export const textTemplates = [
    {
        name: "Header 1",
        color: "3e3e3e",
        lineHeight: "normal",
        fontSize: "4rem",
        font: "FMBasuru",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "0",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Header 2",
        color: "3e3e3e",
        lineHeight: "normal",
        fontSize: "3rem",
        font: "FMBasuru",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "0",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Header 3",
        color: "3e3e3e",
        lineHeight: "normal",
        fontSize: "2.2rem",
        font: "FMBasuru",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "0",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Header 4",
        color: "3e3e3e",
        lineHeight: "normal",
        fontSize: "1.8rem",
        font: "FMBasuru",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "0",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Header 5",
        color: "3e3e3e",
        lineHeight: "normal",
        fontSize: "1.4rem",
        font: "FMBasuru",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "0",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Header 6",
        color: "3e3e3e",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMBasuru",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "0",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Paragraph 1",
        color: "black",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMMalithi",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        textIndent: "50px",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px auto 5px auto",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Paragraph - Normal",
        color: "#292929",
        lineHeight: "1.5",
        fontSize: "1.2rem",
        font: "FMMalithi",
        textAlign: "left",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "",
        borderRadius: "",
        margin: "0",
        padding: "0",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        name: "Quated Paragraph",
        color: "#4f4f4f",
        lineHeight: "1.5",
        fontSize: "1.3rem",
        font: "FMGanganee",
        textAlign: "justify",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "#eaeaea",
        borderRadius: "15px",
        margin: "0",
        padding: "50px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    }
]

export const imageGridTemplates = [
    {
        name: "Grid 1",
        caption: {
            color: "grey",
            lineHeight: "normal",
            fontSize: "1rem",
            font: "FMMalithi",
            textAllignment: "center",
            position: "center",
            letterSpacing: "normal",
            text: "mdidÈl fikiqk"
        },
        backgroundColor: "white",
        borderRadius: "5px",
        gap: "5",
        padding: "5px",
        border: "1px solid black",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
        columns: "3",
        justifyContent: "center",
        alignItems: "center",
    },
]

export const textLitsTemplates = [
    {
        name: "List 1",
        color: "blacck",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMMalithi",
        textAlign: "center",
        position: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
]

export const buttonTemplates = [
    {
        name: "Outlined 1",
        font: "FMMalithi",
        color: "warning",
        position: "center",
        margin: "auto",
        variant: "outlined",
        size: "small",
        href: "#",
    },
    {
        name: "Outlined 2",
        font: "FMMalithi",
        color: "warning",
        position: "center",
        margin: "auto",
        variant: "outlined",
        size: "small",
        href: "#",
    },
]

export const cardTemplates = [
    {
        name: "Card 1",
        cards: [
            {
                media: {
                    component: "img",
                    height: "140"
                },
                header: {
                    color: "black",
                    fontSize: "1rem",
                    fontFamily: "FMMalithi",
                },
                description: {
                    color: "grey",
                    fontSize: "0.8rem",
                    fontFamily: "FMMalithi",
                },
            }
        ],
        maxWidth: "",
        margin: "5px",
        padding: "5px",
        position: "center",
        border: "1px solid black",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
    },
]

export const containerTemplates = [
    {
        name: "Common 1",
        width: "80%",
        height: "auto",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        borderRadius: "5px",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
        position: "center",
        backgroundColor: "white",
        backgroundImage: "none",
        transition: "fade-up",
    },
]