import { API_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';
import { _post_open, _put_open } from '../fetch-api';

export const getUserRoleSuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserRoleSuggestions}`;
  _get(callback, endpointURL);
};

export const getUserSuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserSuggestions}`;
  _get(callback, endpointURL);
};

export const getUserRoleById = (callback, roleId) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserRoleById}/${roleId}`;
  _get(callback, endpointURL);
};

export const profileUpdate = (body, callback, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getProfile}`;
  _put(callback, endpointURL, body, errCallBack);
};

export const passwordChange = (body, callback, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.changePassword}`;
  _put(callback, endpointURL, body, errCallBack);
};

export const passwordReset = (body, callback, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.passwordReset}`;
  _post_open(callback, endpointURL, body, {}, errCallBack);
};

export const passwordResetUpdate = (body, callback, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.passwordReset}`;
  _put_open(callback, endpointURL, body, errCallBack);
};

export const profile = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getProfile}`;
  _get(callback, endpointURL);
};

export const activate = (code, body, callback, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.activate}/${code}`;
  _post_open(callback, endpointURL, body, {}, errCallBack);
};

export const blockUser = (body,callback, id,errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.blockUser}/${id}/updateStatus`;
  _put(callback, endpointURL, body, errCallBack);
};

export const getAllUsers = (
  callback,
  errorCallback,
  pageNumber,
  pageSize,
  userType,
  status,
  keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getAllUsers}`;
  const params = {
    pageNumber,
    pageSize,
    userType,
    status,
    keyword,
    sortProperty: 'createdDate',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const updateUserStatus = (body,callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateStatus}/${id}/updateStatus`;
  _put(callback, endpointURL, body, errCallBack);
};

export const userCreate = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.userCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const userUpdate = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.userUpdate}`;
  _put(callback, endpointURL, body, errCallBack);
};

export const deleteUserById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deleteUserById}/${id}`;
  _delete(callback, endpointURL);
};

export const loadUserById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadUserById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};
