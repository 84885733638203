import { API_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';

export const searchPayments = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    searchParams
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.paymentSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    sortProperty: 'transactionDate',
  };
  let requestParams = {...params, ...searchParams};
  _get(callback, endpointURL, requestParams, null, errorCallback);
};

export const createPayment = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.paymentCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deletePaymentById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.paymentDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getPaymentById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getPaymentById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getNextUniqueId = (callback, errorCallback) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getNextPaymentUniqueId}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getUserAuthoritySuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserAuthoritySuggestions}`;
  _get(callback, endpointURL);
};

export const updatePayment = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updatePayment}`;
  _put(callback, endpointURL, body, errCallBack);
};
