import {
    Box,
    Button, Collapse,
    FormControl, FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import { Cancel, Delete, Done, Edit } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import { useState } from "react";
import { MuiColorInput } from "mui-color-input";
import useDidUpdateEffect from "../../../hooks/commonHooks";

export const WrapContainerSettingsBar = (props) => {

    const [collapse, setCollapse] = useState(false);

    const [width, setWidth] = useState(props.data.width)
    const [height, setHeight] = useState(props.data.height)
    const [position, setPosition] = useState(props.data.position)
    const [transition, setTransition] = useState(props.data.transition)
    const [backgroundColor, setBackgroundColor] = useState(props.data.backgroundColor)
    const [backgroundImage, setBackgroundImage] = useState(props.data.backgroundImage)
    const [boxShadow, setBoxShadow] = useState(props.data.boxShadow)
    const [margin, setMargin] = useState(props.data.margin)
    const [padding, setPadding] = useState(props.data.padding)
    const [border, setBorder] = useState(props.data.border)
    const [borderRadius, setBorderRadius] = useState(props.data.borderRadius)

    const handleChangeWidth = (event) => {
        setWidth(event.target.value);
    };
    const handleChangeHeight = (event) => {
        setHeight(event.target.value);
    };
    const handleChangeMargin = (event) => {
        setMargin(event.target.value);
    };
    const handleChangePadding = (event) => {
        setPadding(event.target.value);
    };
    const handleChangeBorder = (event) => {
        setBorder(event.target.value);
    };
    const handleChangeBorderRadius = (event) => {
        setBorderRadius(event.target.value);
    };
    const handleChangeBoxShadow = (event) => {
        setBoxShadow(event.target.value);
    };
    const handleChangePosition = (event) => {
        setPosition(event.target.value);
    };
    const handleChangeBackgroundColor = (value) => {
        setBackgroundColor(value);
    };
    const handleChangeBackgroundImage = (event) => {
        setBackgroundImage(event.target.value);
    };
    const handleChangeTransition = (event) => {
        setTransition(event.target.value);
    };
    
    useDidUpdateEffect(() => { 
        const temp = {
            width: width,
            height: height,
            margin: margin,
            padding: padding,
            border: border,
            borderRadius: borderRadius,
            boxShadow: boxShadow,
            position: position,
            backgroundColor: backgroundColor,
            backgroundImage: backgroundImage,
            transition: transition,
        }
        props.handleOnFormSubmit(temp)
    }, [width, height, margin, padding, border, borderRadius,
        position, transition, 
        backgroundColor, boxShadow, backgroundImage])

    return (
        <div>
            <AppBar position="static" sx={{
                backgroundColor: props.hasToBeSaved ? "red" : "primary",
                margin: 0,
            }}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        SETTINGS
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {props.hasToBeSaved && (
                            <Button
                                variant={"outlined"}
                                onClick={() => {
                                    props.handleOnSave()
                                }}
                            >
                                Save
                            </Button>
                        )}
                        <IconButton
                            size="large"
                            aria-label="show 4 new mails"
                            color="inherit"
                            onClick={props.onDelete}
                        >
                            <Delete />
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            onClick={() => setCollapse(!collapse)}
                        >
                            <Edit />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            required
                            id="width-input"
                            label="Width"
                            onChange={handleChangeWidth}
                            value={width}
                            style={{ width: '100px' }}
                            size="small"
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            required
                            id="height-input"
                            label="height"
                            onChange={handleChangeHeight}
                            value={height ? height : ""}
                            style={{ width: '100px' }}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="position-label"> Position</InputLabel>
                            <Select
                                labelId="position-label"
                                id="position-input"
                                defaultValue=""
                                value={position ? position : "center"}
                                onChange={handleChangePosition}
                                autoWidth
                                label=" Position"
                                size="small"
                            >
                                <MenuItem value={'center'}>Center</MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: '12ch', marginRight: 0 }}>
                            <InputLabel id="transition-label"> Transition</InputLabel>
                            <Select
                                labelId="transition-label"
                                id="transition-input"
                                defaultValue=""
                                value={transition ? transition : ""}
                                onChange={handleChangeTransition}
                                autoWidth
                                label="Transition"
                                size="small"
                            >
                                <MenuItem value={""}>None </MenuItem>
                                <MenuItem value={'fade'}>Fade </MenuItem>
                                <MenuItem value={'fade-up'}>Fade up </MenuItem>
                                <MenuItem value={'fade-left'}>Fade left</MenuItem>
                                <MenuItem value={'fade-down'}>Fade down</MenuItem>
                                <MenuItem value={'fade-right'}>Fade right</MenuItem>
                                <MenuItem value={'fade-up-right'}>Fade up right</MenuItem>
                                <MenuItem value={'fade-up-left'}>Fade up left</MenuItem>
                                <MenuItem value={'fade-down-right'}>Fade down right</MenuItem>
                                <MenuItem value={'fade-down-left'}>Fade down left</MenuItem>
                            </Select>
                        </FormControl>
                        <MuiColorInput
                            format="hex"
                            id="background-color-input"
                            label="Background Color"
                            onChange={handleChangeBackgroundColor}
                            value={backgroundColor ? backgroundColor : ""}
                            size="small"
                            style={{ width: '150px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            required
                            id="background-image-input"
                            label="Background Image"
                            onChange={handleChangeBackgroundImage}
                            value={backgroundImage ? backgroundImage : ""}
                            size="small"
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            required
                            id="border-shadow-input"
                            label="Border shadow"
                            onChange={handleChangeBoxShadow}
                            value={boxShadow ? boxShadow : ""}
                            size="small"
                            style={{ width: '150px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border"
                            id="border-input"
                            value={border}
                            onChange={handleChangeBorder}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Radius"
                            id="border-radius-input"
                            value={borderRadius}
                            onChange={handleChangeBorderRadius}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Margin"
                            id="margin-input"
                            value={margin}
                            onChange={handleChangeMargin}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Padding"
                            id="padding-input"
                            value={padding}
                            onChange={handleChangePadding}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <Button
                            color={'error'}
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => setCollapse(!collapse)}
                            endIcon={<Cancel />}
                        ></Button>
                    </Toolbar>
                </AppBar>
            )}
        </div>
    )
}