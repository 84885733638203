import { API_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';

export const searchVendors = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.vendorSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'name',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createVendor = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.vendorCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deleteVendorById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.vendorDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getVendorById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getVendorById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getVendorSuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getVendorSuggestions}`;
  _get(callback, endpointURL);
};

export const updateVendor = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateVendor}`;
  _put(callback, endpointURL, body, errCallBack);
};
