import { API_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';

export const searchUtilityAccounts = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.utilityAccountSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'createdDate',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createUtilityAccount = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.utilityAccountCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deleteUtilityAccountById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.utilityAccountDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getUtilityAccountById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUtilityAccountById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getNextUniqueId = (callback, errorCallback) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getNextUtilityAccountUniqueId}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getUserAuthoritySuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserAuthoritySuggestions}`;
  _get(callback, endpointURL);
};

export const updateUtilityAccount = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateUtilityAccount}`;
  _put(callback, endpointURL, body, errCallBack);
};
