import {API_CONFIGS} from '../../config'
import {BFF_ENDPOINTS} from '../../utils/constants'
import { _get, _put, _post ,_delete } from '../fetch-api'

export const createContentWrapContainer = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.contentWrapContainerCreate}`
  _post(callback, endpointURL, data, {}, errCallBack)
}

export const updatePageContainerOrder = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updatePageContainerOrder}`
  _put(callback, endpointURL, data, errCallBack)
}

export const deleteContentWrapContainerById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.contentWrapContainerDelete}/${id}`
  _delete(callback, endpointURL, errCallBack)
}

export const getContentWrapContainerById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getContentWrapContainerById}/${id}`
  _get(callback, endpointURL, {}, null, errorCallback)
}

export const updateContentWrapContainer = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateContentWrapContainer}`
  _put(callback, endpointURL, body, errCallBack)
}

export const getContentWrapContainerSuggestions = (callback, errCallBack, parentId) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getContentWrapContainerSuggestions}/${parentId}`
  _get(callback, endpointURL, {}, null, errCallBack)
}
