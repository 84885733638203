import {API_CONFIGS} from "../config";
import {BFF_ENDPOINTS} from "../utils/constants";
import {_delete, _post} from "./fetch-api";

export const uploadFile = (callback, file, onUploadingProgress, path, referenceId, errCallback) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.uploadFile}`;
    const bodyFormData = new FormData();
    bodyFormData.set('path', path);
    bodyFormData.set('referenceId', referenceId);
    bodyFormData.append('file', file, file.fileName);
    _post(
        callback,
        endpointURL,
        bodyFormData,
        {
            headers: {'Content-Type': 'multipart/form-data'},
            onUploadProgress: (progressEvent) => {
                onUploadingProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        },
        errCallback,
    );
};

export const deleteFile = (callback, path, errCallback) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deleteFile}?filePath=${path}`;
    _delete(
        callback,
        endpointURL,
        errCallback,
    );
};