import { API_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';

export const searchVouchers = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.voucherSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'issuedDate',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createVoucher = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.voucherCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deleteVoucherById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.voucherDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getVoucherById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getVoucherById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getNextUniqueId = (callback, errorCallback) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getNextVoucherUniqueId}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getVoucherSuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getVoucherSuggestions}`;
  _get(callback, endpointURL);
};

export const updateVoucher = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateVoucher}`;
  _put(callback, endpointURL, body, errCallBack);
};
