import { useEffect, useState } from "react";
import {
    Box, Button,
    Collapse,
    IconButton, Typography,
} from "@mui/material";
import { Delete, Edit, Save } from "@mui/icons-material";
import { FilesUploadForm } from "../common/filesUploadForm";
import { FILE_PATH_CONST } from "../../../utils/constants";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { createUpdateThumbnail, deleteThumbnail, } from "../../../client/thumbnail/call";
import { API_CONFIGS } from "../../../config";

export const CreateThumbnailForm = (props) => {

    const [collapse, setCollapse] = useState(false);

    const [image, setImage] = useState(props.thumbnail ? props.thumbnail : null);

    const onUploadSuccess = (data) => {
        setImage(data.file)
    }
    const handleOnDelete = () => {
        deleteThumbnail(() => {
            toast(`Thumbnail was deleted ....`,
                {
                    closeOnClick: true,
                    type: "success",
                    theme: "light",
                    icon: <CheckCircleOutlineIcon />
                });
            setImage(null)
        }, props.parentId, props.parentType, (err) => {
            toast(`Error: ${err}`,
                {
                    closeOnClick: true,
                    type: "error",
                    theme: "light",
                    icon: <ErrorOutlineIcon />
                });
        })
    }

    const createOrUpdate = () => {
        let temp = {
            filePath: image,
            parentId: props.parentId,
            parentType: props.parentType,
        }
        createUpdateThumbnail(() => {
            toast(`Thumbnail was created/updated ....`,
                {
                    closeOnClick: true,
                    type: "success",
                    theme: "light",
                    icon: <CheckCircleOutlineIcon />
                });
        }, temp, (err) => {
            if (err.validationFailures) {
                err && err.validationFailures.map((e) => {
                    toast(`Error: ${e.code}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon />
                        });
                })
            } else {
                toast(`Error: ${err}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon />
                    });
            }
        })
    }

    return (
        <Box
            sx={{
                margin: '1rem',
                padding: '1rem',
                borderRadius: "1rem",
                justifyContent: "center",
                border: '1px solid grey',
            }}>
            <Typography
                align={"center"}
            >
                Upload thumbnail
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {image && (
                        <img
                            style={{
                                margin: "2rem",
                            }}
                            src={`${API_CONFIGS.fileBaseUrl}${image}`}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        size="large"
                        color="red"
                        onClick={() => setCollapse(!collapse)}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        size="large"
                        color="red"
                        onClick={handleOnDelete}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '27ch', },
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <FilesUploadForm
                            containerId={props.parentId}
                            path={`${FILE_PATH_CONST.images}/thumbnail/${props.parentId}`}
                            handleOnUploadSuccess={onUploadSuccess}
                        />
                    </div>
                    <Button
                        size="medium"
                        variant="outlined"
                        onClick={createOrUpdate}
                        endIcon={<Save />}
                    >Save</Button>
                </Box>
            </Collapse>
        </Box>
    )
}