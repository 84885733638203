import {API_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_delete, _get, _get_open, _post, _put} from "../fetch-api";

export const searchLocations = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.searchLocations}`;
    const params = {
        pageNumber,
        pageSize,
        status,
        keyword,
        sortProperty: 'name',
    };
    _get(callback, endpointURL, params, null, errorCallback);
};

export const createLocation = (callback, data, errCallBack) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.createLocation}`;
    _post(callback, endpointURL, data, {}, errCallBack);
};

export const updateLocation = (callback, body, errCallBack) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateLocation}`;
    _put(callback, endpointURL, body, errCallBack);
};

export const deleteLocationById = (callback, id, errCallBack) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deleteLocationById}/${id}`;
    _delete(callback, endpointURL, errCallBack);
};

export const loadLocationById = (callback, errorCallback, id) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadLocationById}/${id}`;
    _get(callback, endpointURL, {}, null, errorCallback);
};

export const loadLocationsSuggestions = callback => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadLocationSuggestions}`;
    _get(callback, endpointURL);
};
