import { API_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';

export const searchUserRoles = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.userRoleSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'name',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createUserRole = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.userRoleCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deleteUserRoleById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.userRoleDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getUserRoleById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserRoleById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getUserAuthoritySuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserAuthoritySuggestions}`;
  _get(callback, endpointURL);
};

export const updateUserRole = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateUserRole}`;
  _put(callback, endpointURL, body, errCallBack);
};
