
export const BFF_ENDPOINTS = {
  getSampleList: 'users',
  login: 'oauth/token',
  logout: 'users/logout',
  getUserRoleSuggestions: 'roles/suggestions',
  userSignUp: 'users/sign-up',
  getProfile: 'users/profile',
  changePassword: 'users/password-change',
  passwordReset: 'users/password-reset',
  getAllUsers: 'users',
  updateStatus: 'users',
  userCreate: 'users',
  userUpdate: 'users',
  deleteUserById: 'users',
  loadUserById: 'users',
  getUserSuggestions: 'users/suggestions',

  getUserRoleById: 'roles',
  userRoleSearch: 'roles',
  userRoleCreate: 'roles',
  updateUserRole: 'roles',
  userRoleDelete: 'roles',
  getUserAuthoritySuggestions: 'authorities/suggestions',

  getAuthorityById: 'authorities',
  authoritiesSearch: 'authorities',
  authorityCreate: 'authorities',
  authorityDelete: 'authorities',
  updateAuthority: 'authorities',
  deshanaAccessSuggestions: 'authorities/suggestions/deshana',

  deshanaSearch: 'deshana',
  deshanaSearchByWebLinkUsers: 'deshana/by-web-link',
  loadDirectoryById: 'deshana',
  directoryCreate: 'deshana',
  createOrUpdateWebLink: 'deshana/{id}/web-link',
  directoryUpdate: 'deshana',
  deshanaDelete: 'deshana',
  uploadDeshana: 'deshana/upload',
  downloadDeshana: 'deshana/download',
  findDeshanaIdByWebLink: 'deshana/web-link',

  searchFoundations: 'foundations',
  createFoundation: 'foundations',
  updateFoundation: 'foundations',
  deleteFoundationById: 'foundations',
  loadFoundationById: 'foundations',
  loadFoundationsSuggestions: 'foundations/suggestions-all',

  searchLocations: 'monasteries',
  createLocation: 'monasteries',
  updateLocation: 'monasteries',
  deleteLocationById: 'monasteries',
  loadLocationById: 'monasteries',
  loadLocationSuggestions: 'monasteries/suggestions-all',

  uploadPageBodyImages: 'page-body-images/upload',

  searchEvents: 'events',
  createEvent: 'events',
  updateEvent: 'events',
  deleteEventById: 'events',
  loadEventById: 'events',
  loadEventSuggestions: 'events/suggestions-all',

  getItemById: 'items',
  itemSearch: 'items',
  itemCreate: 'items',
  updateItem: 'items',
  itemDelete: 'items',
  getItemSuggestions: 'items/suggestions',

  getPaymentById: 'payments',
  paymentSearch: 'payments',
  paymentCreate: 'payments',
  updatePayment: 'payments',
  paymentDelete: 'payments',
  getPaymentSuggestions: 'payments/suggestions',
  getNextPaymentUniqueId: 'payments/next-id',

  getDiscountById: 'discounts',
  discountSearch: 'discounts',
  discountCreate: 'discounts',
  updateDiscount: 'discounts',
  discountDelete: 'discounts',
  getDiscountSuggestions: 'discounts/suggestions',

  getAssetById: 'assets',
  assetSearch: 'assets',
  assetCreate: 'assets',
  updateAsset: 'assets',
  assetDelete: 'assets',
  getAssetSuggestions: 'assets/suggestions',
  getNextAssetUniqueId: 'assets/next-id',

  getEmployeeById: 'employees',
  employeeSearch: 'employees',
  employeeCreate: 'employees',
  updateEmployee: 'employees',
  employeeDelete: 'employees',
  getEmployeeSuggestions: 'employees/suggestions',
  getNextEmployeeUniqueId: 'employees/next-id',

  getUtilityAccountById: 'utility-accounts',
  utilityAccountSearch: 'utility-accounts',
  utilityAccountCreate: 'utility-accounts',
  updateUtilityAccount: 'utility-accounts',
  utilityAccountDelete: 'utility-accounts',
  getUtilityAccountSuggestions: 'utility-accounts/suggestions',
  getNextUtilityAccountUniqueId: 'utility-accounts/next-id',

  getVoucherById: 'vouchers',
  voucherSearch: 'vouchers',
  voucherCreate: 'vouchers',
  updateVoucher: 'vouchers',
  voucherDelete: 'vouchers',
  getVoucherSuggestions: 'vouchers/suggestions',
  getNextVoucherUniqueId: 'vouchers/next-id',

  getVendorById: 'vendors',
  vendorSearch: 'vendors',
  vendorCreate: 'vendors',
  updateVendor: 'vendors',
  vendorDelete: 'vendors',
  getVendorSuggestions: 'vendors/suggestions',

  getItemCategoryById: 'item-categories',
  itemCategoriesSearch: 'item-categories',
  itemCategoryCreate: 'item-categories',
  updateItemCategory: 'item-categories',
  itemCategoryDelete: 'item-categories',
  getItemCategorySuggestions: 'item-categories/suggestions',
  getAssociateSuggestions: 'associates/suggestions',

  getExpensesGraphData: 'reports/finance/expense-graph',

  uploadFile: 'file-upload',
  deleteFile: 'file-delete',

  getContentWrapContainerById: 'page-content-containers',
  contentWrapContainersSearch: 'page-content-containers',
  contentWrapContainerCreate: 'page-content-containers',
  updateContentWrapContainer: 'page-content-containers',
  contentWrapContainerDelete: 'page-content-containers',
  updatePageContainerOrder: 'page-content-containers/change-order',
  getContentWrapContainerSuggestions: 'page-content-containers/suggestions',

  createOrUpdateThumbnail: 'thumbnail',
  deleteThumbnail: 'thumbnail'

};

export const FILE_PATH_CONST = {
  images : "images",
  pdf : "pdf"
}