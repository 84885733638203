import {API_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_get} from "../fetch-api";

export const getExpensesGraphData = (
    callback,
    errorCallback,
    searchParams
) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getExpensesGraphData}`;
    _get(callback, endpointURL, searchParams, null, errorCallback);
};
